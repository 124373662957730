/* @import url('/src/components/Ocenowo/Font/XRXV3I6Li01BKofINeaB.woff2'); */

$bp : (mobile : 480px,
    tablet : 1040px,
    desktop : 1440px,
    mega : 3000px,
);

@mixin query($display) {
    @each $key, $value in $bp {

        //  defining max-width
        @if ($display ==$key) {
            @media (max-width: $value) {
                @content;
            }
        }
    }
}

*{
    font-family: "Nunito", sans-serif; 
  }
  
  body {
    margin: 0;
    padding: 0;
    background-color: #111122;
    scrollbar-color: white;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: "Nunito", sans-serif; 
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }
  
  main {
    width: calc(100%);
    display: flex;
    flex-direction: column;
    padding: 2vh;
  }
  
  #welcome {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: row;
    margin-top: 7vh;
  }
  
  
  
  #featuresect [id^="collapsible-trigger"]{
    all: unset;
    font-size: 30px;
    border-left: #fff solid 1px;
    border-top: #fff solid 1px;
    border-right: #fff solid 1px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;  
    padding: 1vh;
  }
  
  #featuresect [id^="collapsible-content"]{
    /* all: unset; */
    display: flex;
    flex-direction: row;
  }
  
  #featuresect .Collapsible{
    width: calc(50% - 1vh);
  
  }
  
  #featuresect .Collapsible .Collapsible__contentInner{
    display: flex;
  }
  
  
  #discordsect {
    height: calc(500px + 4vh);
    width: 100%;
    // background-color: #222222;
    display: flex;
    flex-direction: row;
    color: white;
    margin-top: 2vh;
    justify-content: center;
  }
  @media (max-width: 480px) {
    #discordsect {
      display: none;
    }
  }
  @media (max-width: 1440px) {
    #discordsect {
      display: flex;
    }
  }
  @media (max-width: 1040px) {
    #discordsect {
      display: none;
    }
  }
  @media (max-width: 1440px) {
    #discordsectmobile {
      display: none;
    }
  }
  @media (max-width: 3000px) {
    #discordsectmobile {
      display: none;
    }
  }
  @media (max-width: 1040px) {
    #discordsectmobile {
      display: flex;
      color: white;
      flex-direction: column;
      padding: 2vh;
    }
    #discordsectmobile h2 {
      font-weight: 400;
    }
    #discordsectmobile a {
      color: white;
      font-weight: 600;
    }
  }
  
  #faqsect {
    background-color: #111122;
  }
  
  footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 15vh;
    background-color: #111122;
    color: white;
  }
  footer a {
    color: white;
  }
  
  .footerdiv {
    width: 100%;
  }
  
  .badge {
    // width: 20vh;
    height: 5vh;
    text-align: center;  
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1vh;
    margin-right: 1vh;
    position: relative;
  }
  @media (max-width: 480px) {
    .badge {
      width: 15vh;
      margin-left: auto;
    margin-right: auto;
    }
  }
  .badge img {
    height: 100%;
  }
  @media (max-width: 480px) {
    .badge img {
      height: unset;
      height: 100%;
    }
  }
  
  #downloads {
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  @media (max-width: 480px) {
    #downloads {
      flex-direction: row;
      height: unset;
    }
  }
  
  #credits {
    text-align: center;
    width: 100%;
  }
  @media (max-width: 480px) {
    #credits p {
      padding: 2vh;
    }
  }
  
  #faq {
    background-color: #111122;
    color: white;
    text-align: center;
    padding: 2vh;
  }
  #faq h2 {
    font-weight: 500;
    margin-top: 0;
  }
  
  #featurerow{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
  }
  
  .feature {
    background-color: #111122;
    color: #fff;
    text-align: center;
    padding-top: 1vh;
    width: 50%;
  }
  @media (max-width: 1040px) {
    .feature {
      width: 100%;
      height: auto;
    }
  }
  .feature h2 {
    font-weight: 500;
    padding: 1vh;
    padding-bottom: 0;
    font-size: 30px;
    margin-bottom: 0;
    margin-top: 7vh;

    @include query(mobile){
        font-size: 24px;
        margin-top: unset;
    }
  }
  .feature p {
    padding: 1vh;
    padding-top: 0;
    font-size: 24px;
    font-weight: 400;

    @include query(mobile){
        font-size: 18px;
    }
  }
  
  .featureimage{
    height: 60vh;
    /* padding: 3vh; */
    /* width: 40%; */
    text-align: center;
  }
  
  .featureimage img{
    height: 100%;
  }
  
  #frame {
    width: 100%;
    height: 70px;
    // background-color: #222;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  
  .dc-block {
    width: 30%;
    height: calc(500px + 4vh);
    background-color: #111122;
    color: white;
    text-align: justify;

    @include query(desktop){
      width: 40%;
    }

    @include query(mobile){
      width: 100%;
      height: unset;
    }
  }
  .dc-block h2 {
    font-weight: 500;
    padding: 0vh;
    padding-bottom: 0;
    font-size: 30px;
    margin-bottom: 0;
    padding-right: 2vh;
  }
  .dc-block p {
    padding: 0vh;
    padding-top: 0;
    font-size: 24px;
    padding-right: 2vh;
  }
  .dc-block iframe {
    margin-left: auto;
    margin-right: auto;
  }
  
  #left {
    width: 50%;
    display: flex;
    justify-content: center;
    z-index: 1;
    font-size: 42px;
    font-weight: 400;
    color: white;
    flex-direction: column;
  }
  @media (max-width: 1040px) {
    #left {
      width: 100%;
      height: 80vh;
    }
  }
  #left div {
    
    justify-content: center;
  }
  #left div p {
    margin-top: auto;
    /* margin-bottom: auto; */
    padding: 2vh;
  }
  
  #left .badge{
    all: unset;
    display: flex;
  }
  
  #left .badge a img{
    // all: unset;
    height: 100%;    
  }
  
  #herobadges{
    display: flex;
      margin-left: auto;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      order: 2;

      @include query(mobile){
        order: 3;
      }
  }
  
  #herobadges .badge{
    height: 5vh;
      padding: 2vh;
      padding-top: 0;
  }
  
  #right {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 1040px) {
    #right {
      width: 0%;
    }
  }
  
  #topr {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1040px) {
    #topr {
      display: none;
    }
  }
  #topr img {
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  
  #topP {
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
    background-color: #111;
    width: 100%;
    background: url("../../../public/img/Ocenowo/HeroMockup2.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  
  #botr {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  @media (max-width: 1040px) {
    #botr {
      display: none;
    }
  }
  
  #l2 {
    width: 50%;
    height: 100%;
    background-color: blueviolet;
    background: url("../../../public/img/Ocenowo/HeroMockup.png");
    background-size: cover;
  }
  
  #l2 img{
    width: 100%;
  }
  
  #r2 img{
    width: 100%;
  }
  
  #r2 {
    width: 50%;
    height: 100%;
    background-color: green;
    background: url("../../../public/img/Ocenowo/HeroMockup.png");
    background-size: cover;
  }
  
  header {
    height: 7vh;
    display: flex;
    color: aliceblue;
    font-size: large;
    z-index: 3;
    position: fixed;
    width: 100%;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
  header img {
    height: 60%;
    padding-left: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    margin-top: auto;
    margin-bottom: auto;
  }
  @media (max-width: 480px) {
    header img {
      /* margin-left: auto; */
      margin-right: auto;
      padding-top: 1vh;
      height: 90%;
    }
  }
  header div {
    height: 100%;
    padding-left: 1vh;
    padding-right: 1vh;
    transition: 0.2s;
    display: flex;
  }
  header div p {
    margin-top: auto;
    margin-bottom: auto;
  }
  @media (max-width: 480px) {
    header div {
      display: none;
    }
  }
  header div:hover {
    background-color: #ffffff1f;
    transition: 0.2s;
  }
  header a {
    color: white;
    text-decoration: none;
    font-weight: 400;
    height: 100%;
    display: flex;
  }
  
  #discord {
    padding-right: 1.5vh;
    padding-left: 1.5vh;
  }
  
  #dzienniczek{
    margin-left: auto;
  }
  
  #functionCollapsible{
    all: unset;
  }
  
  .collapsible {
    background-color: #111122;
    border: solid 2px #666;
    margin-top: 1vh;
    border-radius: 12px;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 15px;
    display: flex;
    transition: 0.2s;
    font-family: "Fredoka", sans-serif;
  }
  
  
  
  .content {
    color: white;
    padding: 0 18px;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: black;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #584dd6;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #584dd6;
  }/*# sourceMappingURL=style.css.map */
  
  [id^="collapsible-trigger"]{
    border-left: #fff solid 1px;
    border-top: #fff solid 1px;
    border-right: #fff solid 1px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    display: block;
    padding: 1vh;
  }
  
  [id^="collapsible-content"]{
    transition-duration: 0.1s !important;
    border-right: #fff solid 1px;
    border-left: #fff solid 1px;
    border-bottom: #fff solid 1px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding-left: 1vh;
    padding-right: 1vh;
  }
  
  [id^="collapsible-content"] p {
    padding-bottom: 1vh;
  }
  
  .Collapsible{
    padding-bottom: 1vh;
  }
  
  #frame{
    margin-top: 1vh;
  }
  
  #featuresect {
    /* background-color: #222; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-top: -20vh; */
  }
  @media (max-width: 1040px) {
    #featuresect {
      flex-direction: column;
      height: auto;
    }
  }
  
  #featurepresentation{
    width: 70%;
    background-color: #11112c;
    height: 52vh;
    text-align: center;
    padding: 2vh;
    border: 1px solid white;
    border-radius: 12px;
    border-top-right-radius: 0px;
  }
  
  #featurepresentation img{
    height: calc(100% - 4vh);
    margin-top: 2vh;
  }
  
  #featureselection{
    width: 30%;
    height: 50vh;
    /* background-color: #fff; */
    text-align: center;
  
  }
  
  #selection h2{
    /* color: #111; */
    padding: 2vh;
  }
  
  .selected{
    background-color: #11112c;
    border: 1px solid white;
    border-radius: 12px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
    margin-left: -1px;
  }
  
  #featureborder{
    display: flex;
      border-radius: 12px;
      margin: 2vh;
      border: 1px solid white;
      padding: 1vh;
      min-width: 44%;
  }
  
  @media (max-width: 1040px) {
    #herobadges .badge {
      margin-top: auto;
    }
  }
  
  #scrolldown{
    font-size: 18px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 8vh;
    order: 3;
    
    @include query(mobile){
      order: 2;
    }
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  
  #scrollarrow{
    background-color: #ffffffe3;
    height: 6vh !important;
    width: 6vh;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    color: #111;
    display: flex;  
    animation: blinker 3s linear infinite;
  }
  
  #scrollarrow:hover{
    background-color: #ffffffe3;
  }
  
  #welcometext {
    margin-top: auto;  
    position: relative;
    transform: translateX(-500px);
    opacity: 0;
    overflow: hidden;
    -webkit-animation: slide 0.4s forwards;
    animation: slide 0.4s forwards;
    animation-delay: 0.5s;
    backface-visibility: hidden;
    text-align: center;
    order: 1;

    @include query(mobile){
      text-align: unset;
    }
}
  
#welcometext p {
    font-family: unset !important;
}
  
@-webkit-keyframes slide {
    100% { transform: translateX(0); opacity: 1;}
}
  
@keyframes slide {
    100% { transform: translateX(0); opacity: 1;}
}

  
  .arrow {
    color: #111;
    border: solid black;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 5px;
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: auto;
    margin-bottom: auto;
  }
  
  #scrollarrow p{
    margin-top: auto;
    padding: 0;
    margin-bottom: auto;
  }
  
  #whitespace{
    height: 7vh;
  }
  
  #mobile{
    flex-direction: column;
    margin-top: auto;

    
    @include query(tablet){
        display: none;
    }
    @include query(desktop){
        display: none;
    }
    @include query(mega){
        display: none;
    }
    @include query(mobile){
        display: flex;
    }
  }
  
  #desktop{
    flex-direction: column;
    margin-top: auto;

    @include query(desktop){
        display: flex;
    }

    @include query(mobile){
        display: none;
    }
    
    
  }

#faqelement{
    border: 1px solid white;
    border-radius: 12px;
    padding: 2vh;
    margin-bottom: 2vh;
}

#faqelement h3{
    padding-bottom: 1vh;
}
  

#mobileNav{
  display: none;
  background-color: #11112200;
  border: 0;
  z-index: 1;
  width: 7vh;

  @include query(mobile){
    display: unset;
  }
}

#mobileNav svg{
  height: 1.5em;
}

.menu{
  all: unset;  
  display: none;
  backface-visibility: hidden;
  transition: transform 0.4s ease-in-out !important;
  position: fixed;
  left: 4vh;
  top: 0;
  width: 34vh;
  background-color: #111122 !important;
  height: 89vh;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  -webkit-box-shadow: -12px 0px 16px 0px #000;
  -moz-box-shadow: -12px 0px 16px 0px #000;
  box-shadow: -12px 0px 16px 0px #000;
  flex-direction: column;
  padding: 4vh;
  padding-top: 7vh;
  padding-left: 3vh;

  @include query(mobile){
    display: flex;
  }

  a{
    all: unset;
    // background-color: #584dd6;
    height: 7vh;
    display: flex;
  }

  p{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.5vh;
    font-size: 22px;
  }
}

.open{
  transform: translateX(4vh);  
}

.close{
  transform: translateX(150%);
}

#navIcon{
  width: 7vh;
  height: 7vh;
  display: flex;
  padding: 0;

  img{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

#navCredits{
  width: 100%;
  height: 7vh;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 4vh;

  a{
    text-decoration: underline;
  }

  p{
    all: unset;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.discord {
  height:70px;
	width:250px;
  border: 0;
}

#badgeImage{
  height: 100%;
  transition: 1s;
}

#badgeImage img{
  transition: 0.2s;
  opacity: 1;
}

#badgeImage img:hover{
  opacity: 0.8;
  transition: 0.2s;
}

#carousel img{
  width: 20vh;
}

#clear{
  all: unset;
}

//troubleshooting

#troubleshooting{
    width: 100%;     
    padding: 2vh;
  p{
    text-align: center;
    font-size: 32px;
  }
}

#adblocklist{
  padding: 2vh;
}

.trouble{  
  margin-top: unset;
  width: 50%;
  margin-left: auto;
  margin-right: auto;

  @include query(tablet){
    width: 96vw;
  }
}

#dns{
  width: 100%;
  padding-top: 2vh;
  padding-bottom: 2vh;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.settingIcon{
  width: 128px;
  padding-top: 2vh;
  margin-right: 2vh;
  padding-bottom: 2vh;

  @include query(mobile){
    width: 64px;
    
  }
}

#konkurs{
  h1{
    text-align: center;
    padding: 4vh;
  }
  p{
    font-size: 1.2em;
  }
}

#contestCard{
  border: white 1px solid;
  border-radius: 12px;
  padding: 2vh;
  margin: 2vh;
  // height: 12vh;

  ul{
    margin-top: 1vh;
  }

  li{
    margin-left: 2vh;
  }

  h2:first-of-type{
    margin-top: 0;
  }

  h2{
    margin-top: 1vh;
  }

  p{
    margin-top: 1vh;
  }

  img{
    min-width: 20vh;
  }
}