@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #6029B3;
  border-radius: 10px;
}

.App{
  background-color: #0E152A;
  padding: 10px 0 0;
  overflow-x: hidden;
}

nav{
  background: #0E152A;
  position: fixed;
  top: 0;
  padding: 10px 70px;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
}

nav .logo{
  width: 270px;
  height: 100%;
  display: flex;
  align-items: center;
}

nav .logo img{
  height: 100%;
}

nav .links{
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
  font-size: 20px;
  font-weight: 700;
}

nav .links a{
  text-decoration: none;
}

nav button{
  display: none;
}

@media (max-width: 1000px) {
  nav {
      padding: 10px 20px;
  }

  nav .logo img{
    height: 80%;
  }

  nav .links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #6029B3;
      z-index: 5;
  }

  nav .links.open {
      display: flex;
      height: 100vh;
      position: fixed;
  }

  nav .links a {
      padding: 15px;
      text-align: center;
      width: 100%;
      box-sizing: border-box;
  }

  nav button {
      display: block;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 24px;
      z-index: 6;
      position: fixed;
      right: 20px;
  }
}

.hero{
  width: 100%;
  height: calc(100vh - 50px);
  position: relative;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  gap: 50px;
}

.hero h1{
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  position: absolute;
  top: 100px;
}

.hero img{
  width: 1200px;
  height: auto;
  position: absolute;
  bottom: -400px;
}

.hero .topElement{
  position: absolute;
  top: -30px;
}


.hero .bottomElement{
  position: absolute;
  bottom: 0;
  z-index: -1;
}

@media (max-height: 900px) and (min-width: 600px){
  .hero img{
    bottom: -500px;
  }
}
@media (max-height: 800px) and (min-width: 600px){
  .hero img{
    bottom: -600px;
  }
}

@media (max-width: 1000px) {
  .hero h1{
    font-size: 38px;
  }
}

@media (max-width: 600px) {
  .hero img{
    width: 1000px;
  }
}

@media (max-width: 400px) {
  .hero img{
    width: 900px;
  }
}

.functions{
  width: 100%;
  height: 650px;
  position: relative;
  background-image: url('../../../public/img/Dzienniczek/bg.png');
  background-size: cover;
}

.functions .content{
  width: 100vw;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.functions .content .slide.active{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  transform: scale(1);
  transition: 0.37s all ease-in;
}

.functions .content .slide{
  transform: scale(0);
  position: absolute;
}

.functions .content .slide .text{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 40%;
  font-size: 20px;
  gap: 20px;
}

.functions .content .slide .text h2{
  width: 100%;
  font-size: 40px;
  font-weight: 800;
}


.functions .controls{
  padding: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.functions .controls .btn{
  width: 50px;
  height: 50px;
  border: none;
  background: none;
  font-size: 30px;
}

@media (max-width: 1000px) {
  .functions .content .slide img{
    display: none;
  }

  .functions .content .slide .text{
    text-align: center;
    width: 60%;
  }
}


.other{
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
}

.other .column{
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  padding: 50px 20px;
}

.other .column h3{
  width: 100%;
  padding: 15px 0px;
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 32px;
  font-weight: 500;
}

.other .column p{
  padding: 10px;
  font-size: 22px;
  font-weight: 300;
}

@media (max-width: 700px) {
  .other{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  .other .column{
    width: 100%;
  }
  .other .column h3{
    justify-content: center;
  }
}

.download{
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  margin: 20px 0 0;
}

.download h3{
  width: 100%;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
}

.download .btnBox{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 75px;
}

.download .btnBox a{
  width: 250px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #6029B3;
  border-radius: 50px;
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
  transition: 0.5s all ease-in;
}

.download .btnBox a:hover{
  transform: scale(1.1);
}

@media (max-width: 700px) {
  .download{
    height: 400px;
  }

  .download .btnBox{
    flex-wrap: wrap;
    gap: 10px !important;
  }
}

.discordF{
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.discordF h2{
  width: 100%;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
}

.discordF a{
  width: 250px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #6029B3;
  border-radius: 50px;
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
  transition: 0.5s all ease-in;
}

.discordF a:hover{
  transform: scale(1.1);
}

#footerD{
  margin-top: 80px;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  gap: 5px;
}

@media (max-width: 600px) {
  #footerD{
    height: auto;
    text-align: center;
  }
}

.list{
  width: 100%;
  min-height: 100vh;
  margin-top: 70px;
  padding: 70px;
}

.list h2{
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
}

.list h4{
  margin-top: 15px;
  font-size: 24px;
}

.list ul{
  padding: 10px 30px;
}